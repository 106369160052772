<template>
  <div class="journalism" :style="'min-height:' + minHeight + 'px'">
    <div class="details">
      <header>
        <div class="el-icon-arrow-left" @click="returnPage('新闻')">返回</div>
        <p>
          <span @click="returnPage('返回首页')">首页</span> /
          <span @click="returnPage('新闻')">新闻</span> / 新闻详情
        </p>
      </header>
      <main>
        <h1>{{ newsDetailMsg.newsTitle }}</h1>
        <p><i class="el-icon-time"></i>{{ newsDetailMsg.createTime }}</p>
        <div class="ql-editor" v-html="newsDetailMsg.newsDetail"></div>
      </main>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import { newsDetail } from '@/api/journalism'
import Footer from '@/components/Appfooter/footerbottom.vue'
import moment from 'moment'
export default {
  components: {
    Footer
  },
  props: {
    minHeight: Number
  },
  data() {
    return { newsDetailMsg: {} }
  },
  methods: {
    //   获取新闻详细
    async getNewsDatails(newsId) {
      const { code, data } = await newsDetail(newsId)
      if (code === '0000') {
        data.newsDetail = data.newsDetail.replaceAll(
          '<img',
          "<img style='width:100%;margin-top:10px;'"
        )
        data.newsDetail = data.newsDetail.replaceAll('<strong', '<b')
        data.newsDetail = data.newsDetail.replaceAll('</strong', '</b')
        data.createTime = moment(data.createTime).format('YYYY-MM-DD')
        this.newsDetailMsg = data
      }
    },
    returnPage(e) {
      if (e === '返回首页') {
        this.$router.push('/phone/home')
        return
      }
      if (e === '新闻') {
        this.$router.push('/phone/journalism')
      }
    }
  },
  created() {
    const newsId = this.$route.query.newsId || {}
    this.getNewsDatails(newsId)
  }
}
</script>

<style lang="less" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px;
}

.MsoNormal {
  line-height: 60px !important;
  letter-spacing: 30px !important;
}
.journalism {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.details {
  background-color: #fff;
  padding: 47px 20px 0 20px;

  header {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    width: 714px;
    height: 72px;
    background: #ecebed;
    border-radius: 4px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    padding: 0 24px;
    color: #29262a;
  }
  main {
    padding: 20px;
    h1 {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #060313;
      line-height: 53px;
    }
    p {
      color: #8e8c8f;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      i {
        padding-right: 20px;
        font-size: 30px;
      }
    }
    /deep/p {
      line-height: 50px;
      letter-spacing: 5px !important;
    }
  }
}
</style>
